@font-face {
font-family: 'scoreDream';
src: url(/_next/static/media/04096189043deab5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'scoreDream';
src: url(/_next/static/media/f98e6b46d9dc52e6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'scoreDream Fallback';src: local("Arial");ascent-override: 82.65%;descent-override: 23.05%;line-gap-override: 3.95%;size-adjust: 108.89%
}.__className_342f19 {font-family: 'scoreDream', 'scoreDream Fallback'
}

@font-face {
font-family: 'ySpotlight';
src: url(/_next/static/media/98567fd337dc8fdc-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: 'ySpotlight Fallback';src: local("Arial");ascent-override: 86.85%;descent-override: 27.79%;line-gap-override: 0.00%;size-adjust: 115.15%
}.__className_e43d9f {font-family: 'ySpotlight', 'ySpotlight Fallback';font-weight: 500;font-style: normal
}

@font-face {
font-family: 'nanumGothicCoding';
src: url(/_next/static/media/d9b4dbeb06b3474e-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: 'nanumGothicCoding Fallback';src: local("Arial");ascent-override: 73.01%;descent-override: 18.25%;line-gap-override: 0.00%;size-adjust: 109.58%
}.__className_b37cad {font-family: 'nanumGothicCoding', 'nanumGothicCoding Fallback';font-weight: 500;font-style: normal
}

@font-face {
font-family: 'HakgyoansimKkwabaegiOTFR';
src: url(/_next/static/media/3fdbeb194008224b-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: 'HakgyoansimKkwabaegiOTFR Fallback';src: local("Arial");ascent-override: 67.69%;descent-override: 25.38%;line-gap-override: 0.00%;size-adjust: 118.19%
}.__className_1dae9f {font-family: 'HakgyoansimKkwabaegiOTFR', 'HakgyoansimKkwabaegiOTFR Fallback';font-weight: 500;font-style: normal
}

